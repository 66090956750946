import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { migrateBodyPaddings } from '@wix/shoutout-email-react';

import { i18n } from './i18n';
import { CampaignContext, ConfigContext } from './contexts';
import { Viewer } from './components/viewer';
import { MjmlViewer } from './components/mjml-viewer';
import { parseComposerData, encodeHTMLInRegions } from './client-utils';
import { fedops } from './fedops';

fedops.appLoadStarted();

const config = window.__CONFIG__;
const i18nInstance = i18n(config.locale);
const message = parseComposerData(window.__CAMPAIGN__);
const isMjmlMessage =
  ['mjml', 'MJML_WEB'].includes(message.data.source) &&
  message.data.composerData.mjml;

let campaign = null;
if (isMjmlMessage) {
  campaign = message;
} else {
  campaign = migrateBodyPaddings(encodeHTMLInRegions(message));
}

ReactDOM.render(
  <Suspense fallback="">
    <I18nextProvider i18n={i18nInstance}>
      <CampaignContext.Provider value={campaign}>
        <ConfigContext.Provider value={config}>
          {isMjmlMessage ? <MjmlViewer /> : <Viewer />}
        </ConfigContext.Provider>
      </CampaignContext.Provider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById('root'),
);
