import React from 'react';
import mjmlBrowser from 'mjml-browser';

import { useCampaign } from '../../contexts';

export const MjmlViewer = () => {
  const campaign = useCampaign();
  const html = mjmlBrowser(campaign.data.composerData.mjml).html;
  return (
    <iframe
      title="mjml"
      srcDoc={html}
      style={{
        width: `100%`,
        height: `100%`,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        border: 0,
      }}
    />
  );
};
